import parents1 from '../../img/GORCNKERNER/1.png'
import parents2 from '../../img/GORCNKERNER/2.png'
import parents3 from '../../img/GORCNKERNER/3.png'
import parents4 from '../../img/GORCNKERNER/4.png'
import parents5 from '../../img/GORCNKERNER/5.png'
import parents6 from '../../img/GORCNKERNER/6.png'
import parents7 from '../../img/GORCNKERNER/7.png'
import parents8 from '../../img/GORCNKERNER/8.png'
import parents9 from '../../img/GORCNKERNER/9.png'
import parents10 from '../../img/GORCNKERNER/10.png'
import parents11 from '../../img/GORCNKERNER/11.png'
import parents12 from '../../img/GORCNKERNER/12.png'
import parents13 from '../../img/GORCNKERNER/13.png'
import parents14 from '../../img/GORCNKERNER/14.png'
import parents15 from '../../img/GORCNKERNER/15.png'
import parents16 from '../../img/GORCNKERNER/16.png'
import parents17 from '../../img/GORCNKERNER/17.png'
import parents18 from '../../img/GORCNKERNER/18.png'
import parents19 from '../../img/GORCNKERNER/19.png'
import parents20 from '../../img/GORCNKERNER/20.png'
import parents21 from '../../img/GORCNKERNER/21.png'
import parents22 from '../../img/GORCNKERNER/22.png'
import parents23 from '../../img/GORCNKERNER/23.png'
import parents24 from '../../img/GORCNKERNER/24.png'
import parents25 from '../../img/GORCNKERNER/25.png'
import parents26 from '../../img/GORCNKERNER/26.png'
import parents27 from '../../img/GORCNKERNER/27.png'
import parents28 from '../../img/GORCNKERNER/28.png'
import parents29 from '../../img/GORCNKERNER/29.png'
import parents30 from '../../img/GORCNKERNER/30.png'
import parents31 from '../../img/GORCNKERNER/31.png'
import parents32 from '../../img/GORCNKERNER/32.png'
import parents33 from '../../img/GORCNKERNER/33.png'
import parents34 from '../../img/GORCNKERNER/34.png'
import parents35 from '../../img/GORCNKERNER/35.png'
import parents36 from '../../img/GORCNKERNER/36.png'
import parents37 from '../../img/GORCNKERNER/37.png'
import parents38 from '../../img/GORCNKERNER/38.png'
import parents39 from '../../img/GORCNKERNER/39.png'
import parents40 from '../../img/GORCNKERNER/40.png'
import parents41 from '../../img/GORCNKERNER/41.png'
import parents42 from '../../img/GORCNKERNER/42.png'
import parents43 from '../../img/GORCNKERNER/43.png'
import parents44 from '../../img/GORCNKERNER/44.png'
import parents45 from '../../img/GORCNKERNER/45.png'
import parents46 from '../../img/GORCNKERNER/46.png'
import parents47 from '../../img/GORCNKERNER/47.png'
import parents48 from '../../img/GORCNKERNER/48.png'
import parents49 from '../../img/GORCNKERNER/49.png'
import parents50 from '../../img/GORCNKERNER/50.png'
import parents51 from '../../img/GORCNKERNER/51.png'
import parents52 from '../../img/GORCNKERNER/52.png'
import parents53 from '../../img/GORCNKERNER/53.png'
import parents54 from '../../img/GORCNKERNER/54.png'
import parents55 from '../../img/GORCNKERNER/55.png'
import parents56 from '../../img/GORCNKERNER/56.png'
import parents57 from '../../img/GORCNKERNER/57.png'
import parents58 from '../../img/GORCNKERNER/58.png'
import parents59 from '../../img/GORCNKERNER/59.png'
import parents60 from '../../img/GORCNKERNER/60.png'
import parents61 from '../../img/GORCNKERNER/61.png'
import parents62 from '../../img/GORCNKERNER/62.png'
import parents63 from '../../img/GORCNKERNER/63.png'
import parents64 from '../../img/GORCNKERNER/64.png'
import parents65 from '../../img/GORCNKERNER/65.png'
import parents66 from '../../img/GORCNKERNER/1.png'
import parents67 from '../../img/GORCNKERNER/67.png'
import parents68 from '../../img/GORCNKERNER/1.png'

export default [
    parents1,parents2,parents3,parents4,parents5,parents6,parents7,parents8,parents9,parents10,
    parents11,parents12,parents13,parents14,parents15,parents16,parents17,parents18,
    parents19,parents20,parents21,parents22,parents23,parents24,parents25,parents26,
    parents27,parents28,parents29,parents30,parents31,parents32,parents33,parents34,
    parents35,parents36,parents37,parents38,parents39,parents40,parents41,parents42,
    parents43,parents44,parents45,parents46,parents47,parents48,parents49,parents50,
    parents51,parents52,parents53,parents54,parents55,parents56,parents57,parents58,
    parents59,parents60,parents61,parents62,parents63,parents64,parents65,parents66,
    parents67,parents68
]