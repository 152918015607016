import galery1 from '../../img/tesadaran/1.png'
import galery2 from '../../img/tesadaran/2.png'
import galery3 from '../../img/tesadaran/3.png'
import galery4 from '../../img/tesadaran/4.png'
import galery5 from '../../img/tesadaran/5.png'
import galery6 from '../../img/tesadaran/6.png'
import galery7 from '../../img/tesadaran/7.png'


export default [galery1,galery2,galery3,galery4,galery5,galery6,galery7]

