export default  [
    {id:1,background:'rgb(255, 253, 208)'},
    {id:2,background:'rgb(255, 253, 179)'},
    {id:3,background:'rgb(255, 255, 139)'},
    {id:4,background:'rgb(254, 249, 105)'},
    {id:5,background:'rgb(252, 200, 53)'},
    {id:6,background:'rgb(240, 179, 73)'},
    {id:7,background:'rgb(212, 100, 22)'},
    {id:8,background:'rgb(225, 220, 237)'},
    {id:9,background:'rgb(181, 217, 238)'},
    {id:11,background:'rgb(165, 154, 152)'},
    {id:12,background:'rgb(150, 104, 133)'},
    {id:13,background:'rgb(21, 72, 115)'},
    {id:14,background:'rgb(58, 60, 110)',},
    {id:15,background:'rgb(83, 47, 73)'},
    {id:16,background:'rgb(255, 195, 213)'},
    {id:17,background:'rgb(255, 223, 175)'},
    {id:18,background:'rgb(253, 219, 210)'},
    {id:19,background:'rgb(210, 107, 70)'},
    {id:20,background:'rgb(193, 62, 80)'},
    {id:21,background:'rgb(214, 97, 103)'},
    {id:22,background:'rgb(241, 45, 38)'},
    {id:23,background:'rgb(99, 177, 62)'},
    {id:24,background:'rgb(128, 211, 51)'},
    {id:25,background:'rgb(160, 186, 115)'},
    {id:26,background:'rgb(53, 114, 53)'},
    {id:27,background:'rgb(64, 95, 28)'},
    {id:28,background:'rgb(46, 45, 50)'},
    {id:29,background:'rgb(92, 71, 68)'},
    {id:30,background:'rgb(247, 245, 77)'},
    {id:31,background:'rgb(0, 0, 0)'},
    {id:32,background:'rgb(255, 255, 255)'},
]