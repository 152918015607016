export default {
    AM: {
        addres:'Հասցե',
        mail: 'Mail',
        tel: 'Հեռախոսահամար',
        ecopen: 'EcoPen',
        HeaderNav: {
            home: "Գլխավոր",
            about: "մեր մասին",
            gallery: "տեսադարան",
            partners: "գործընկերներ",
            order: "պատվիրել"
        },
        WhyChooseUs: 'ինչու՞ ընտրել',
        cardText: {
            ecology: 'Էկոլոգիա',
            quality: 'Որակ',
            stylish: 'Նորաոճ'
        },
        penOrder:{
            main:'Գլխավոր Էջ',
            heading:'Ընտրեք ձեր գրիչի արտաքին տեսքը',
            penColor:'Գրիչի գույնը',
            pointSize:'Միջուկի հաստություն',
            inkColor:'Միջուկի գույնը',
            price:'Գին ',
            amd:'Դրամ ',
            add:'ԱՎԵԼԱՑՆԵԼ',
            toGo:'ԱՌԱՋ',
            quantity:'Քանակությունը',
            pensInfo:{
                price1:'1-9 հատ - մեկ հատի արժեքը 500դր',
                price2:'10-29 հատ - մեկ հատի արժեքը 400դր.',
                price3:'30 հատ և ավելի - մեկ հատի արժեքը 300դր.',
                infDop:'(արժեքը ներառում է անհատական տպագրությունը)'
            }

        },
        text1: '1-9 հատ - մեկ հատի արժեքը 500դր. 10-29 հատ - մեկ հատի արժեքը 400դր. 30 հատ և ավելի - մեկ հատի արժեքը 300դր. (արժեքը ներառում է անհատական տպագրությունը)',
        text2:'Ներկայացնում ենք Ձեզ հայկական արտադրության ձեռքով պատրաստված էկոգրիչներ: Գրիչները թղթից են, կարող են լինել տարբեր գույների, ինչպես նաև Ձեր կազմակերպության անվանումով կամ տարբերանշանով: Օգտվելով այս առաջարկից՝ դուք ոչ միայն կխթանեք հայկական արտադրությունը, այլև կսահմանափակեք պլաստմասսե պիտույքների կիրառումը՝ նպաստելով շրջակա միջավայրի պաշտպանությանը: Էկոգրիչը պատրաստվում է բացառապես ձեռքով, արտադրված է Հայաստանում: Գրիչներն արտաքինից միանման են, հավասարաչափ, համասեռ փաթույթներով, ունեն ապրանքային տեսք:  Կափարիչը կիպ համընկնում և փակում է գրիչը: Գրիչի միջուկը գնդիկավոր է, օգտագործելիս գրում է շատ փափուկ: Ծայրի հաստությունը 0.7 կամ 1.0 մմ է: Թանաքը կարող է լինել կապույտ, կարմիր, սև և կանաչ գույների, ունի 3-5 տարի պահպանման ժամկետ: Առաջարկում ենք ավելի քան 20 գրիչի գույն: ',
        
    },
    EN: {
        addres:'Address',
        mail: 'Mail',
        tel: 'Tel',
        ecopen: 'EcoPen',
        HeaderNav: {
            home: "home",
            about: "about",
            gallery: "Gallery",
            partners: "Partners",
            order: "Order online"
        },
        WhyChooseUs: 'Why Choose Us',
        cardText: {
            ecology: 'Ecology',
            quality: 'Quality',
            stylish: 'Stylish'
        },
        penOrder:{
            main:'home page',
            heading:'Choose the appearance of your pen',
            penColor:'Pen color',
            pointSize:'Point size',
            inkColor:'Ink color',
            price:'Price',
            amd:'AMD',
            add:'Add to the list',
            toGo:'Click to go forward ',
            quantity:'Quantity',
            pensInfo:{
                price1:'For 1-9 pcs. - the cost of 1pcs. is 500 AMD.',
                price2:'For 10-29 pcs. - the cost of 1pcs. is 400 AMD.',
                price3:'For 30 pcs. and more - the cost of  1pcs. is 300 AMD',
                infDop:'( the price includes individual printing )'
            }
        },
        text1: "For 1-9 pcs. - the cost of 1pcs. is 500 AMD.For 10-29 pcs. - the cost of 1pcs. is 400 AMD.For 30 pcs. and more - the cost of  1pcs. is 300 AMD (the price includes individual printing)",
        text2: ''
    },
    RU: {
        addres:'Адрес',
        mail: 'Mail',
        tel: 'Номер телефона',
        ecopen: 'EcoPen',
        HeaderNav: {
            home: "Главная",
            about: "О-нас",
            gallery: "Галерея",
            partners: "Партнеры",
            order: "Заказать"
        },
        WhyChooseUs: 'Почему выбрать ',
        cardText: {
            ecology: 'Экология',
            quality: 'Качество',
            stylish: 'Модный'
        },
        penOrder:{
            main:'главная страница',
            heading:'Выберите внешний вид вашей ручки',
            penColor:'Цвет ручки',
            pointSize:'Толщина стержня',
            inkColor:'Цвет стержня',
            price:'Цена',
            amd:'Драм',
            add:'Добавить',
            toGo:'Вперед',
            quantity:'Количество',
            pensInfo:{
                price1:'1-9 шт. - стоимость одной  500 др.',
                price2:'10-29 шт. - стоимость одной 400 др.',
                price3:'30 шт. и больше - стоимость одной 300 др.',
                infDop:'( стоимость включает в себя индивидуальную печать )'
            }
        },
        text1:'1-9 шт. - стоимость одной  500 др. 10-29 шт. - стоимость одной 400 др. 30 шт. и больше - стоимость одной 300 др. ( стоимость включает в себя индивидуальную печать) ',
        text2:'Ներկայացնում ենք Ձեզ հայկական արտադրության ձեռքով պատրաստված էկոգրիչներ: Գրիչները թղթից են, կարող են լինել տարբեր գույների, ինչպես նաև Ձեր կազմակերպության անվանումով կամ տարբերանշանով: Օգտվելով այս առաջարկից՝ դուք ոչ միայն կխթանեք հայկական արտադրությունը, այլև կսահմանափակեք պլաստմասսե պիտույքների կիրառումը՝ նպաստելով շրջակա միջավայրի պաշտպանությանը: Էկոգրիչը պատրաստվում է բացառապես ձեռքով, արտադրված է Հայաստանում: Գրիչներն արտաքինից միանման են, հավասարաչափ, համասեռ փաթույթներով, ունեն ապրանքային տեսք:  Կափարիչը կիպ համընկնում և փակում է գրիչը: Գրիչի միջուկը գնդիկավոր է, օգտագործելիս գրում է շատ փափուկ: Ծայրի հաստությունը 0.7 կամ 1.0 մմ է: Թանաքը կարող է լինել կապույտ, կարմիր, սև և կանաչ գույների, ունի 3-5 տարի պահպանման ժամկետ: Առաջարկում ենք ավելի քան 20 գրիչի գույն: '
    }
}