import pen1 from './images/1.png';
import pen2 from './images/2.png';
import pen3 from './images/3.png';
import pen4 from './images/4.png';
import pen5 from './images/5.png';
import pen6 from './images/6.png';
import pen7 from './images/7.png';
import pen8 from './images/8.png';
import pen9 from './images/9.png';
import pen10 from './images/10.png';
import pen11 from './images/11.png';
import pen12 from './images/12.png';
import pen13 from './images/13.png';
import pen14 from './images/14.png';
import pen15 from './images/15.png';
import pen16 from './images/16.png';
import pen17 from './images/17.png';
import pen18 from './images/18.png';
import pen19 from './images/19.png';
import pen20 from './images/20.png';
import pen21 from './images/21.png';
import pen22 from './images/22.png';
import pen23 from './images/23.png';
import pen24 from './images/24.png';
import pen25 from './images/25.png';
import pen26 from './images/26.png';
import pen27 from './images/27.png';
import pen28 from './images/28.png';
import pen29 from './images/29.png';
import pen30 from './images/30-t.png';
import pen31 from './images/31.png';


export default [
                pen1,
                pen2,
                pen3,
                pen4,
                pen5,
                pen6,
                pen7,
                pen8,
                pen9,
                pen10,
                pen11,
                pen12,
                pen13,
                pen14,
                pen15,
                pen16,
                pen17,
                pen18,
                pen19,
                pen20,
                pen21,
                pen22,
                pen23,
                pen24,
                pen25,
                pen26,
                pen27,
                pen28,
                pen29,
                pen30,
                pen31,
            ]

